import React from 'react';

function Home() {
  return <div>
    <div>a</div>
    <div></div>
    <p></p>
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  <h1>Home Page</h1>;
  </div>
  
}

export default Home;